<template>
  <div>
    <div :class="$style.orders">
      <div>
        <Title text="Заказы" position="right" />
      </div>
      <div :class="$style.orders__header">
        <div>
          <date-picker
            v-model="date"
            range
            :lang="lang"
            placeholder="Дата проведения занятия"
            @clear="clearFilter('date')"
          />
          <date-picker
            v-model="createdAt"
            :class="$style.orders__datepicker"
            range
            :lang="lang"
            placeholder="Дата создания"
            @clear="clearFilter('createdAt')"
          />
        </div>
        <Input :class="$style.orders__input" placeholder="Поиск" v-model="filterValue" />
        <div>
          <DefaultSelect
            v-model="filter.statusId"
            :items="statuses"
            :font-size="'12px'"
            :style="{ marginRight: '10px', width: '13rem' }"
            placeholder="Выбрать статус"
            @input="(val) => inputSelect(val, 'statusId')"
          />
          <DefaultSelect
            :items="filterOrderTypes"
            :font-size="'12px'"
            :style="{ margin: '0.625rem 0.625rem 0 0', width: '13rem' }"
            placeholder="Выбрать тип"
            @input="filterTrialOrders"
          />
        </div>
        <DefaultSelect
          v-model="filter.subjectId"
          :items="subjects"
          :font-size="'12px'"
          :style="{ width: '15rem' }"
          placeholder="Выбрать предмет"
          @input="(val) => inputSelect(val, 'subjectId')"
        />
      </div>
      <div :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <img
                v-if="headerColumn.sortValue"
                src="@/assets/icons/arrow.svg"
                alt="arrow"
                v-on:click="sortOrders(headerColumn.sortValue)"
                :class="$style.table__header_column_icon"
              />
            </div>
          </div>
        </div>
        <div :class="$style.table__content">
          <template v-for="order in totalItems">
            <table-row :order="order" :key="order.id" v-if="!Array.isArray(order)" />
            <table-row-group v-else :items="order" :key="order[0].id" />
          </template>

          <div :class="$style.table__content_rowLine">
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.orders__footer">
      <div>
        <pagination
          :pageCount="orderPageCount"
          :prevText="''"
          :click-handler="(page) => onOrderPageChange(page, true)"
          :nextText="''"
          :containerClass="'pagination'"
          :page-class="'page'"
          :forcePage="forcePage"
          :initial-page="this.$route.query.page - 1"
        />
      </div>
      <div :class="$style.orders__footer_button">
        <button @click="onNewOrderButtonClick">Создать заказ</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import orders from '@/api/orders'

import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'

import Title from '@/basic/Title'
import Input from '@/basic/Input'
import TableRow from '@/components/TableRow'
import TableRowGroup from '@/components/TableRowGroup'
import DefaultSelect from '../basic/DefaultSelect'

import paginationMixin from '@/mixins/pagination.mixin'

import 'vue2-datepicker/index.css'

const filterOrderTypes = [
  {
    id: 1,
    value: 'Пробные'
  },
  {
    id: 2,
    value: 'Не пробные'
  }
]

export default {
  name: 'Orders',
  components: {
    TableRowGroup,
    DefaultSelect,
    TableRow,
    Title,
    Input,
    DatePicker
  },
  data() {
    return {
      lang: ru,
      forcePage: 0,
      orders: [],
      date: [],
      createdAt: [],
      filter: {},
      statuses: [],
      subjects: [],
      filterValue: '',
      sortMethod: 'desc',
      sortBy: 'id',
      initialPage: 1,
      headerColumns: [
        {
          id: 1,
          sortValue: 'id',
          title: 'id'
        },
        {
          id: 2,
          sortValue: 'datetime',
          title: 'Дата начала занятия'
        },
        {
          id: 3,
          title: 'Статус'
        },
        {
          id: 4,
          title: 'Преподаватель'
        },
        {
          id: 5,
          title: 'Ученик'
        },
        {
          id: 6,
          title: 'Предмет'
        }
      ],
      filterOrderTypes
    }
  },
  mixins: [paginationMixin],
  computed: {
    ...mapGetters(['constants']),
    moment() {
      return Moment
    },
    startDate() {
      return this.date.length && this.moment(this.date[0]).format('YYYY-MM-DD LT')
    },
    endDate() {
      return this.date.length && this.moment(this.date[1]).format('YYYY-MM-DD LT')
    },
    startCreatedAt() {
      return this.createdAt.length && this.moment(this.createdAt[0]).format('YYYY-MM-DD LT')
    },
    endCreatedAt() {
      return this.createdAt.length && this.moment(this.createdAt[1]).format('YYYY-MM-DD LT')
    },
    filteredByDateOrders() {
      if (
        !this.date.length ||
        (this.startDate === 'Invalid date' && this.endDate === 'Invalid date')
      ) {
        return this.orders
      }
      return this.orders?.filter((order) => {
        return (
          this.moment(order.datetime).isAfter(
            this.moment(this.startDate).format('YYYY-MM-DD')
          ) &&
          this.moment(order.datetime).isBefore(
            this.moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
          )
        )
      })
    },
    filteredOrders() {
      return this.filteredByDateOrders?.filter(
        (order) =>
          order.id.toString().indexOf(this.filterValue) >= 0 ||
          order.status.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) >= 0 ||
          order.teacher?.attributes?.lastName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          order.teacher?.attributes?.firstName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          (order.teacher?.attributes?.middleName &&
            order.teacher?.attributes?.middleName
              .toLowerCase()
              .indexOf(this.filterValue.toLowerCase()) >= 0) ||
          order.student?.attributes?.lastName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          order?.student?.attributes?.firstName
            .toLowerCase()
            .indexOf(this.filterValue.toLowerCase()) >= 0 ||
          (order.student?.attributes?.middleName &&
            order.student?.attributes?.middleName
              .toLowerCase()
              .indexOf(this.filterValue.toLowerCase()) >= 0)
      )
    },
    totalOrders: {
      get() {
        /*console.log( [
          ...new Set(this.filteredOrders.map((order) => (order.groupId ? order.groupId : order)))
        ])
        console.log([
          ...new Set(this.filteredOrders.map((order) => (order.groupId ? order.groupId : order)))
        ].map((item) =>
          typeof item === 'number'
            ? this.filteredOrders.filter((order) => order.groupId === item)
            : item
        ))*/
        return this.filteredOrders
        /*return [
          ...new Set(this.filteredOrders.map((order) => (order.groupId ? order.groupId : order)))
        ].map((item) =>
          typeof item === 'number'
            ? this.filteredOrders.filter((order) => order.groupId === item)
            : item
        )*/
      },
      set(newtotalOrders) {
        return newtotalOrders
      }
    }
  },
  watch: {
    filterValue: {
      handler: _.debounce(async function() {
        await this.onOrderPageChange(1, false)
      }, 1000)
    },
    date: {
      async handler() {
        if (
          !this.date.length ||
          (this.startDate === 'Invalid date' && this.endDate === 'Invalid date')
        ) {
          return this.orders
        }
        await this.onOrderPageChange(1, false)
      }
    },
    createdAt: {
      deep: true,
      async handler() {
        if (
          !this.createdAt.length ||
          (this.startCreatedAt === 'Invalid date' && this.endCreatedAt === 'Invalid date')
        ) {
          return this.orders
        }
        await this.onOrderPageChange(1, false)
      }
    }
  },
  created() {
    for (const [key, value] of Object.entries(this.constants)) {
      if (this[key]) {
        this[key] = value
      }
    }
  },
  async mounted() {
    const page = parseInt(this.$route.query.page)
    const res = await orders.getOrders({
      pagination: {
        offset: page * 10 - 10,
        limit: 10
      },
      scan: {
        fields: [
          'studentFirstName',
          'studentLastName',
          'studentMiddleName',
          'teacherFirstName',
          'teacherLastName',
          'teacherMiddleName',
          'id'
        ],
        value: this.filterValue
      },
      order: {
        field: 'id',
        by: this.sortMethod
      }
    })

    this.orders = res.data.result.rows
    this.setupOrderPagination(this.orders, res.data.result.count)
  },
  methods: {
    ...mapMutations(['setOrderDetails']),

    async getOrders() {
      const page = parseInt(this.$route.query.page)
      const dataFilter = {
        pagination: {
          offset: page * 10 - 10,
          limit: 10
        },
        scan: {
          fields: [
            'studentFirstName',
            'studentLastName',
            'studentMiddleName',
            'teacherFirstName',
            'teacherLastName',
            'teacherMiddleName',
            'id'
          ],
          value: this.filterValue
        },
        order: {
          field: this.sortBy,
          by: this.sortMethod
        }
      }
      dataFilter.filter = {
        ...dataFilter.filter,
        ...this.filter
      }
      if (this.startDate && this.endDate) {
        dataFilter.filter.datetime = {
          start: this.moment(this.startDate).format('YYYY-MM-DD'),
          end: this.moment(this.endDate).add(1, 'days').format('YYYY-MM-DD')
        }
      }
      if (this.startCreatedAt && this.endCreatedAt) {
        dataFilter.filter.createdAt = {
          start: this.moment(this.startCreatedAt).format('YYYY-MM-DD'),
          end: this.moment(this.endCreatedAt).add(1, 'days').format('YYYY-MM-DD')
        }
      }
      const res = await orders.getOrders(dataFilter)

      this.orders = res.data.result.rows
      this.setupOrderPagination(this.orders, res.data.result.count)
    },
    setOrder(order) {
      this.setOrderDetails(order)
      this.$router.push(`/orders/${order.id}`)
    },
    async inputSelect(val, key) {
      if (!val) {
        delete this.filter[key]
      } else {
        this.filter[key] = val.id
      }
      await this.onOrderPageChange(1, false)
    },
    async clearFilter(key = 'date') {
      this[key] = []
      await this.onOrderPageChange(1, false)
    },
    async sortOrders(sortBy) {
      /*console.log(sortValue)

      // this.totalItems = this.totalItems.map(item => Array.isArray(item) ? item[0] : item)
      this.totalOrders = orderBy(
        this.totalOrders.map((item) => (Array.isArray(item) ? item[0] : item)),
        sortValue,
        this.sortMethod
      )*/
      this.sortBy = sortBy
      this.toggleSortMethod()
      await this.getOrders()
    },
    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    },
    onNewOrderButtonClick() {
      this.$router.push(`/orders/new`)
    },
    async onOrderPageChange(page, isPagination = true) {
      this.forcePage = +page - 1
      if (isPagination) {
        await this.$router.push(`?page=${page}`)
      }
      if (!isPagination){
        if (this.$route.query.page !== '1') {
          await this.$router.push(`?page=1`)
        }
      }
      await this.getOrders()
    },
    async filterTrialOrders(e) {
      if (!e) {
        delete this.filter.isTrial
      } else if (e.id) {
        this.filter.isTrial = e.id === 1
      }
      await this.onOrderPageChange(1, false)
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.orders {
  &__header {
    display: flex;
    align-items: flex-start;
    margin: 0 0 2rem;

    &_wrapper {
      display: flex;
      align-items: center;

      p {
        font-size: 0.75rem;
      }
    }
  }

  &__datepicker {
    &:last-child {
      margin: 1rem 0 0;
    }
  }

  &__input {
    width: 15rem;
    margin: 0 0.5rem 0.5rem;
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }
      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 9.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        cursor: pointer;
        transition: all 0.3s ease;
        &_active {
          background: red;
          border-right: 1px solid $table-border;
        }

        &:hover {
          background: #e7e4e4;

          opacity: 0.8;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        padding: 0.7rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 9.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }

      &_rowLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 9.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    &_button {
      width: 200px;
      margin-top: 30px;
      button {
        padding: 0.75rem 1.5rem;
        background: #f1e6e6;
        border: 1px solid #c4c4c4;
        border-radius: 1rem;
        font-size: 1.125rem;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
